.background {
  background-image: url(https://yorunoken.s-ul.eu/88Mh3UnN);
  background-attachment: fixed;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
}

.Refer {
  color: #FFC784;
}

.App-header {
  text-align: center;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  color: white;
  padding-top: 5%;
}

.Bio-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  color: white;
  padding-top: 80px;
}

.bio-container {
  border: 0px solid #FFC784;
  margin-left: 2%;
  margin-right: 2%;
  border-spacing: 200px;
  border-width: 5px;
  border-radius: 50px;
  padding: 0% 3% 3% 3%;
  background-color: rgba(40, 44, 52, 0.85);
}

.Welcome {
  text-align: center;
  color: #ffd87d;
  font-size: 300%;
  font-weight: 400;
  margin: 0;
  padding: 0;
}

.bio {
  padding-left: 60px;
  padding-right: 60px;
  text-align: center;
  text-shadow: #282c34;
  font-size: 1.3rem;
  color: #ffdeb7;
}

.pfp-pic {
  position: relative;
  outline: 2px solid #ccc;
  outline-offset: 2px;
  margin-top: -5px;
  height: 35vmin;
  z-index: 3;
}

.pfp-pic.enlarged {
  outline: 50430px solid rgba(40, 44, 52, 0.89);
  outline-offset: 0px;
  position: relative;
}

.social-icons {
  display: flex;
  justify-content: center;
  margin-top: 25px;
}

.icon {
  animation: icon-unhover 200ms backwards;
  display: inline-block;
  vertical-align: middle;
  margin: 10px;
  width: 72px;
  height: 72px;
  transition: all 0.2s ease-in-out;
}

.icon:not(:hover) {
  opacity: 0.8;
}

.icon:hover {
  animation: icon-hover 200ms forwards;
}

@keyframes icon-hover {
  0% {
    transform: scale(1);
  }

  80% {
    transform: scale(1.18);
  }

  100% {
    transform: scale(1.15);
  }
}

@keyframes icon-unhover {
  0% {
    transform: scale(1.15);
  }

  100% {
    transform: scale(1);
  }
}


.button-top,
.button-bottom {
  position: static;
  font-size: 110%;
  size: 20%;
  padding: 2% 4%;
  margin: 1%;
  background-color: #698ac9;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  opacity: 0.7;
  transition: all 0.2s ease-in-out;
}

.button-bottom:hover,
.button-top:hover {
  opacity: 1;
  transform: scale(1.1);
}

.button-top {
  margin-bottom: 1%;
}

.button-bottom {
  margin-top: 1%;
}

.video-container {
  border: 5px solid #FFC784;
  margin-top: 2%;
  margin-left: 2%;
  margin-right: 2%;
  border-radius: 50px;
  padding: 0% 3% 3% 3%;
  background-color: rgba(40, 44, 52, 0.85);
  box-sizing: border-box;
  position: relative;
}

.iframe {
  display: block;
  max-width: 100%;
  max-height: 100%;
}

.video-title {
  font-size: 1.5rem;
  font-weight: 500;
}

.github-container {
  overflow: auto;
  margin: 10%;
  margin-top: 2%;
  margin-left: 2%;
  margin-right: 2%;
  margin-bottom: 0.5%;
  border: 0px solid #FFC784;
  border-width: 5px;
  border-radius: 50px;
  padding: 0% 3% 3% 3%;
  background-color: rgba(40, 44, 52, 0.85);
  max-width: 88%;
}

.github-title {
  font-size: 1.3rem;
  font-weight: 500;
}

.mia-osu {
  text-decoration: overline 2px;
}

.mia-osu-text {
  font-size: 1.1rem;
  font-weight: 450;
}

.image {
  width: 90%;
  height: auto;
  transition: all 0.3s ease;
}